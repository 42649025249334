import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { __fetchUsers } from "../../../api/user";

import DataTable from "../../common/DataTable";
import useAxios from "../../../hooks/useAxios";
import { Box } from "@mui/system";
import Title from "../../common/Title";
import { Button, Grid, Stack } from "@mui/material";

const User = () => {
  const columns = [
    { field: "id", headerName: "ID", flex: 1 },
    {
      field: "username",
      headerName: "Username",
      flex: 3,
    },
    {
      field: "api_key",
      headerName: "Api Key",
      flex: 2,
    },
    {
      field: "check_bad_areacode",
      headerName: "Check Bad Areacode",
      flex: 2,
    },

  ];

  const nav = useNavigate();
  const [data, setData] = useState([]);

  const [metaData, setMetaData] = useState({
    page: 1,
    page_size: 100,
    total: 1,
  });
  const [response, call, loading] = useAxios({ ...__fetchUsers });

  useEffect(() => {
    call({ params: { page: 1, page_size: 10000 } });
  }, []);

  useEffect(() => {
    if (response && response.status) {
      setMetaData({
        page: response.data.page,
        page_size: response.data.page_size,
        total: response.data.total,
      });
      setData(response.data.data);
    }
  }, [response]);

  return (
    <Box sx={{ width: "100%" }}>
      <Title variant="h5" content={`Users`} gutterBottom />
      <Grid container justifyContent="flex-start" spacing={2}>
        <Grid item sx={{ mb: "10px" }}>
          <Stack spacing={2} direction="row">
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={() => nav("create")}
            >
              Create User
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={() => nav("user_history")}
            >
              Export User Logs
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <DataTable
        disableColumnFilter
        loading={loading}
        data={data}
        columns={columns}
        metaData={{ ...metaData, page_size: 100 }}
        filterMode={"client"}
        handleRowClick={(params) => nav(`${params.id}`)}
      />
    </Box>
  );
};

export default User;
