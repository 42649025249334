import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";

import EsimList from "./EsimList";
import Title from "../../../common/Title";

import { copyData } from "../../../../utils/copy";
import { __getEsims } from "../../../../api/sim";
import useAxios from "../../../../hooks/useAxios";
import { CLIENTS } from "../../../../constants/value";

const UltraEsim = () => {
  const [data, setData] = useState([]);
  const nav = useNavigate();

  const [metaData, setMetaData] = useState({
    page: 1,
    page_size: 100,
    total: 1,
  });
  const [response, call, loading] = useAxios({ ...__getEsims });

  const debouncedFetchData = debounce((query) => {
    console.log(query);
    call(query);
  }, 500);

  useEffect(() => {
    debouncedFetchData({
      params: {
        page: 1,
        page_size: 10000,
        client: CLIENTS.ultra,
      },
    });

    return () => {
      debouncedFetchData.cancel();
    };
  }, []);

  useEffect(() => {
    if (response && response.status) {
      setMetaData({
        page: response.data.page,
        page_size: response.data.page_size,
        total: response.data.total,
      });
      setData(response.data.data);
    }
  }, [response]);

  return (
    <Box sx={{ width: "100%" }}>
      <Title variant="h5" content="Ultra eSIMs" gutterBottom />

      {response ? (
        <>
          <Grid container justifyContent="flex-start" spacing={2}>
            {response && (
              <Grid item>
                <Stack spacing={2} mb={2} direction="row">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => nav("form")}
                  >
                    Add New Sims
                  </Button>
                </Stack>
              </Grid>
            )}
          </Grid>
          <EsimList
            data={data}
            loading={loading}
            meta={{ ...metaData, page_size: 100 }}
            client={true}
            handleColumnClick={(key) => copyData(data, [key])}
          />
        </>
      ) : (
        <Title content="Please wait..." variant="subtitle2" gutterBottom />
      )}
    </Box>
  );
};

export default UltraEsim;
