import React from "react";
import { format } from "date-fns";

import DataTable from "../../../common/DataTable";
import ColumnHeader from "../../../common/ColumnHeader";

const EsimList = ({
  data,
  meta,
  client,
  loading,
  handleSort,
  disableRowClick,
  handlePageChange,
  handleColumnClick,
  handleFilterChange,
  handlePageSizeChange,
  ...props
}) => {
  const columns = [
    { field: "id", headerName: "ID", flex: 1, sortable: false },
    {
      field: "iccid",
      headerName: "ICCID",
      minWidth: 200,
      flex: 3,
      sortable: false,
      renderHeader: (params) =>
        client ? (
          <ColumnHeader
            label={"ICCID"}
            handleClick={() => handleColumnClick("iccid")}
          />
        ) : (
          "ICCID"
        ),
    },
    {
      field: "archive",
      headerName: "Archived",
      sortable: false,
    },
    {
      field: "created_at",
      headerName: "Created At",
      minWidth: 200,
      filterable: false,
      flex: 3,
      sortable: false,
      valueGetter: (params) =>
        params.row.created_at &&
        `${format(new Date(params.row.created_at), "MMMM d, y hh:mm a")}`,
    },
    {
      field: "last_updated",
      headerName: "Last Updated",
      minWidth: 200,
      filterable: false,
      flex: 3,
      sortable: false,
      valueGetter: (params) =>
        params.row.last_updated &&
        `${format(new Date(params.row.last_updated), "MMMM d, y hh:mm a")}`,
    },
  ];

  return (
    <DataTable
      disableColumnFilter
      loading={loading}
      data={data}
      columns={columns}
      metaData={meta}
      handlePageChange={handlePageChange}
      handlePageSizeChange={handlePageSizeChange}
      filterMode={client ? "client" : "server"}
      client={client}
      {...props}
    />
  );
};

export default EsimList;
